import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import Carousel from "../components/carousel"
import ContactSection from "../components/contactSection"
import Container from "../components/container"
import Header from "../components/header"
import InnerSection from "../components/innerSection"
import TextSection from "../components/textSection"
import Layout from "../components/layout"

import Logo from '../images/logo-header.png'
import Photo1 from '../../static/img/czamaninek-new.jpg'
import Photo2 from '../../static/img/czamaninek-new-2.jpg'
import Photo3 from '../../static/img/czamaninek-new-3.jpg'
import Photo4 from '../../static/img/czamaninek-new-4.jpg'

import "./o-firmie.css"

const MisjaPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <Container>
        <TextSection className="misja-firmy-description description-medium justify" data={data.page.frontmatter.text_sections[0]} />
      </Container>

      <InnerSection className="misja-firmy-1 image-full">
        <div className="description-container">
            <TextSection className="misja-firmy-description description-medium align-left" data={data.page.frontmatter.text_sections[1]} />
        </div>
        <div className="image-container">
            <img src={Photo1} alt="Czamaninek" />
        </div>
      </InnerSection>

      <InnerSection className="misja-firmy-2 image-full">
        <div className="image-container">
            <img src={Photo2} alt="Czamaninek" />
        </div>
        <div className="description-container">
            <TextSection className="misja-firmy-description description-medium align-left" data={data.page.frontmatter.text_sections[2]} />
        </div>
      </InnerSection>

      <InnerSection className="misja-firmy-3 image-full">
        <div className="description-container">
            <TextSection className="misja-firmy-description description-medium align-left" data={data.page.frontmatter.text_sections[3]} />
        </div>
        <div className="image-container">
            <img src={Photo3} alt="Czamaninek" />
        </div>
      </InnerSection>

      <InnerSection className="misja-firmy-4 image-full">
        <div className="image-container">
            <img src={Photo4} alt="Czamaninek" />
        </div>
        <div className="description-container">
            <TextSection className="misja-firmy-description description-medium align-left" data={data.page.frontmatter.text_sections[4]} />
        </div>
      </InnerSection>

      <AnimationWrapper>
        <Container className="o-firmie-logo-wrapper">
          <img src={Logo} alt="Czamaninek" />
        </Container>
      </AnimationWrapper>

      <section id="realizacje-carousel-section" className="page-section">
        <Carousel data={data.realizacjeCarousel.frontmatter.carousel_gallery} location="realizacje" />
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query MisjaPage{
    page: mdx(frontmatter: { slug: { eq: "misja-firmy" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_sections{
          title
          title_visible
          icon{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          button_text
          button_style
          button_url
          text
        }
      }
    }
    realizacjeCarousel: mdx(
      frontmatter: {slug: {eq: "realizacje-carousel"}, type: {eq: "global"}}
    ){
      frontmatter{
        carousel_gallery{
          text_section{
            title
            title_visible
            subtitle
            text
            button_text
            button_url
            button_style
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            title_visible
            description
            status
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default MisjaPage
